import React from 'react'
import { graphql } from 'gatsby'
import ClientSearch from '../components/ClientSearch'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import get from 'lodash/get'


const SearchTemplate = props => {
  const { pageContext } = props
  const { contentData } = pageContext
  const { allData, options } = contentData
  const heroImage = get(props, 'data.heroImage')
  return (
    <Layout>
      <div id="sub-page">
        <SEO 
          title="USRobotics search" 
          description="Struggling to find a particular web page or piece of content? Use the USRobotics search to hone in on the information that you need." 
          url="https://www.usrobotics.com/search/"
          image={ "https://www.usrobotics.com" + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src }
        />

        <Hero
          class="intro-40"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="Search USRobotics"
          subtitle=""
          type="search"
        />
      </div>

      <section className="bg-gray-light">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <ClientSearch content={allData} engine={options} context="site" />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default SearchTemplate

export const pageQuery = graphql`
  query {
    heroImage: file(name: { eq: "search" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
      }
    }
  }
`